export default [
  "https://web.archive.org/web/20100117211707/http://www.google.com/",
  "https://theoldnet.com/",
  "https://coobies-jaybars-list.neocities.org/",
  "http://myretrogamingroom.website/",
  "https://www.cameronsworld.net/",
  "http://www.geocities.ws/oldternet/files.htm",
  "https://html5zombo.com/",
  "https://kirbysdreamsite.neocities.org/",
  "https://clubnintendoarchives.neocities.org/",
  "https://tixy.land/",
  "https://href.cool/",
];
